import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { PreviewItem } from '../../../../generated-api';
import * as Constants from '../../../../core/helpers/constants/constants';
import { Router } from '@angular/router';
import ItemclassEnum = PreviewItem.ItemclassEnum;
import { SubSink } from 'subsink';
import { Observable, Subject, timer } from 'rxjs';
import { repeatWhen, takeUntil } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey } from '@angular/platform-browser';


@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent implements AfterViewInit {
  static DEFAULT_INTERVAL0 = 4000;
  static DEFAULT_INTERVAL = 6000;

  @Input() slides: PreviewItem[];
  @Input() isHandset = false;
  @Input() isWeb = false;

  activeIdx = 0;
  isServer = false;
  slidesKey = makeStateKey<PreviewItem[]>('slides');
  placeholderPreviewItem: PreviewItem;

  private readonly _stop = new Subject<void>();
  private readonly _start = new Subject<void>();
  autoChange = new Observable();


  subs = new SubSink();
  init = false;

  @ViewChild('indicators') set indicators(i) {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  readonly PLACEHOLDER_IMAGE_WITH_PATH = Constants.PLACEHOLDER_IMAGE_WITH_PATH;

  constructor(private router: Router, private cdr: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: Object, private transferState: TransferState) {
    this.isServer = isPlatformServer(this.platformId);
    if (this.isServer) {
      return;
    }

    this.autoChange = timer(HeroComponent.DEFAULT_INTERVAL0, HeroComponent.DEFAULT_INTERVAL)
    .pipe(takeUntil(this._stop), repeatWhen(() => this._start));

    // If there are slides already populated by the server, we preview them for better web vitals
    if (this.transferState.hasKey(this.slidesKey)) {
      this.placeholderPreviewItem = this.transferState.get(this.slidesKey, [])?.[0];
    }
  }

  goTo(slide: PreviewItem): void {
    switch (slide.itemclass) {
      case ItemclassEnum.Redtext:
        this.router.navigate(['redtext', slide.id]);
        break;
      case ItemclassEnum.Besprechung:
        this.router.navigate(['besprechung', slide.id]);
        break;
      case ItemclassEnum.Ytbesprechung:
        this.router.navigate(['ytbesprechung', slide.id]);
        break;
        case ItemclassEnum.SocialMedia:
          this.router.navigate(['ytbesprechung', slide.id]);
          break;
      case ItemclassEnum.Special:
        this.router.navigate(['special', slide.id]);
        break;
      case ItemclassEnum.Feature:
        if (!this.isServer) {
          const note = document.getElementById('feature-' + slide.id);
          const screenPosition = note.getBoundingClientRect();
          setTimeout(() => {
            window.scroll({
              top: screenPosition.top,
              left: 0,
              behavior: 'smooth'
            });
          }, 100);
        }
        break;
      default:
        this.router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.autoChange.subscribe(value => {
      this.next();
    });
    this.init = true;
  }

  prev(): void {
    this.activeIdx = this.activeIdx === 0 ? this.slides.length - 1 : this.activeIdx - 1;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  next(): void {
    if (this.slides == null) return;
    this.activeIdx = this.activeIdx === this.slides.length - 1 ? 0 : this.activeIdx + 1;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  restartInterval(): boolean {
    this._stop.next();
    this._start.next();
    return true;
  }

  getSlide(idx: number): PreviewItem {
    if (!this.slides || this.slides.length === 0) {
      return null;
    }

    if (this.isServer) {
      this.transferState.set<PreviewItem[]>(this.slidesKey, this.slides);
    }

    return this.slides[idx];
  }
}
